import React,  { useEffect, useState } from 'react'

const NuevaColeccion = ({vars}) => {


  const token = localStorage.getItem("token");

  return (
    <>
        <p style={{ backgroundColor: "#f7f4ef", borderRadius: "9px" }}>
              <br/>
                🚨 <b>NUEVA COLECCIÓN</b> 🚨 <br/>
                <br/>
                ¡Ya tenemos <b>{vars.Categoria} </b><br/>
                <br/>
                Tienen un valor de <b>${vars.PMayorista} pesos al por mayor</b> y <b>${vars.PMinorista} pesos por unidad.</b> <br/>
                <br/>
                Recuerda que también tenemos disponibles: <br/>
                <br/>
                - Gorras 🧢 <br/>
                - Camisetas para hombre 👕 <br/>
                - Camisetas para mujer 👚 <br/>
                - Polos 👕 <br/>
                - Hoodies para Hombre y Mujer 🧥 <br/>
                - Joggers para Hombre y Mujer 👖 <br/>
                - Pantalonetas 🏝️ <br/>
                <br/>
                Para ver todos nuestros productos solo debes hacer CLICK en el botón <b>"ir a Mayoristas"</b> o en el siguiente link: <br/>
                <br/>
                <a href="http://www.basiccouture.com.co/Mayoristas" target="_blank" rel="noreferrer">http://www.basiccouture.com.co/Mayoristas</a> 👈 <br/>
                <br/>
                P.D: Si no quieres seguir recibiendo noticias sobre nuevos productos o promociones, solo escríbenos para eliminarte de nuestros contactos. 
                <br/>
                <br/>
          </p>
    </>
  )
}

export default NuevaColeccion;