//Zona de importacion de componentes externos.
import React, { useRef, useEffect } from "react";
import { Scroll } from "scroll-utility";
import ReactPixel from "react-facebook-pixel";

//Zona de importacion de componentes internos.
import Carousel from "./Carousel";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

function Index({
  headerState,
  modificarHeaderState,
  radioButtons,
  modificarRadioButtons,
  recursosUrlState,
  modificarRecursosUrlState,
  // clienteState,
  // modificarClienteState,
  actualizarSessionStorage,
}) {
  const { clienteState, modificarClienteState } = useGlobalContext();

  /*Los siguientes states son para la lista de banner (Carousel) que se visualiza en el Index
      En total son 5 carousels, cada uno tiene los siguientes atributos:
      id: Numero de referencia.
      srcImagen: ubicacion de almacenamiento de cada imagen del carousel.
      srcBoton: ubicacion de almacenamiento de cada imagen del boton central de cada carousel.
      displayArriba: estado de visualizacion de la flecha que apunta hacia arriba en cada carousel.
      displayAbajo: estado de visualizacion de la flecha que apunta hacia abajo en cada carousel.
    */
  //ReactPixel.track("Home", "ViewContent");
  const carousels = [
    {
      id: 1,
      srcImagen: recursosUrlState + "/img/Index/web/carousel/fondo1",
      displayArriba: "none",
      displayAbajo: "in-line",
    },
    {
      id: 2,
      srcImagen: recursosUrlState + "/img/Index/web/carousel/fondo2",
      displayArriba: "in-line",
      displayAbajo: "in-line",
    },
    {
      id: 3,
      srcImagen: recursosUrlState + "/img/Index/web/carousel/fondo3",
      displayArriba: "in-line",
      displayAbajo: "in-line",
    },
    {
      id: 4,
      srcImagen: recursosUrlState + "/img/Index/web/carousel/fondo4",
      displayArriba: "in-line",
      displayAbajo: "in-line",
    },
    {
      id: 5,
      srcImagen: recursosUrlState + "/img/Index/web/carousel/fondo5",
      displayArriba: "in-line",
      displayAbajo: "none",
    },
  ];

  const radioSeleccionado = {
    width: "15px",
    height: "15px",
  };

  const radioSinSeleccionar = {
    width: "11px",
    height: "11px",
  };

  //Contantes que hacen referencia a cada uno de los carousels del index, con el que se hara uso para la manipulacion de su visualizacion.
  const refCarousel1 = useRef(null);
  const refCarousel2 = useRef(null);
  const refCarousel3 = useRef(null);
  const refCarousel4 = useRef(null);
  const refCarousel5 = useRef(null);

  //Se extraen los atributos del state radioButtons, en total 5, cada uno con sus propiedades css.
  const { radio1, radio2, radio3, radio4, radio5 } = radioButtons;

  //Funciones para el desplazamiento auntomatico de los carousels con estilo de movimiento smooth.
  //Esta funcion hace que el scroll del usuario se desplace automáticamentehacia el carousel indicado.
  const scrollToC1 = () =>
    refCarousel1.current.scrollIntoView({ behavior: "smooth" });
  const scrollToC2 = () =>
    refCarousel2.current.scrollIntoView({ behavior: "smooth" });
  const scrollToC3 = () =>
    refCarousel3.current.scrollIntoView({ behavior: "smooth" });
  const scrollToC4 = () =>
    refCarousel4.current.scrollIntoView({ behavior: "smooth" });
  const scrollToC5 = () =>
    refCarousel5.current.scrollIntoView({ behavior: "smooth" });

  //Toma la posicion relativa de cada uno de los carousels por la que mediante esta, determina cual esta viendo el usuario actualmente y
  //se cambian las propiedades del state de radioButtons para determinar cual es el que se debe mostrar seleccionado.
  const revisar = () => {
    const relativePositionC1 = new Scroll().getRelativeElementPosition("#car1");
    const relativePositionC2 = new Scroll().getRelativeElementPosition("#car2");
    const relativePositionC3 = new Scroll().getRelativeElementPosition("#car3");
    const relativePositionC4 = new Scroll().getRelativeElementPosition("#car4");
    const relativePositionC5 = new Scroll().getRelativeElementPosition("#car5");
    if (
      (relativePositionC5 === 1 && relativePositionC4 !== -0) ||
      relativePositionC5 === -0
    ) {
      modificarRadioButtons({
        radio1:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio2:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio3:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio4:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio5: recursosUrlState + "/img/Index/web/botones/radioSeleccion.webp",
      });
      modificarHeaderState({
        srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha:
          recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.webp",
        displayBuscar: "none",
        displayMenu: "Index",
        color: "negro",
        Seccion: "",
        Cliente: headerState["Cliente"],
        BotonCatalogo: headerState["BotonCatalogo"],
      });
    } else if (
      (relativePositionC4 === 1 &&
        relativePositionC3 !== -0 &&
        relativePositionC5 !== -0) ||
      relativePositionC4 === -0
    ) {
      modificarRadioButtons({
        radio1:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio2:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio3:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio4: recursosUrlState + "/img/Index/web/botones/radioSeleccion.webp",
        radio5:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
      });
      modificarHeaderState({
        srcLogo: recursosUrlState + "/img/Header/logos/logo-blanco.webp",
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha:
          recursosUrlState +
          "/img/Index/web/botones/flecha-derecha-blanca.webp",
        displayBuscar: "none",
        displayMenu: "Index",
        color: "blanco",
        Seccion: "Accesorios",
        Cliente: headerState["Cliente"],
        BotonCatalogo: headerState["BotonCatalogo"],
      });
    } else if (
      (relativePositionC3 === 1 &&
        relativePositionC2 !== -0 &&
        relativePositionC4 !== -0) ||
      relativePositionC3 === -0
    ) {
      modificarRadioButtons({
        radio1:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio2:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio3: recursosUrlState + "/img/Index/web/botones/radioSeleccion.webp",
        radio4:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio5:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
      });
      modificarHeaderState({
        srcLogo: recursosUrlState + "/img/Header/logos/logo-blanco.webp",
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha:
          recursosUrlState +
          "/img/Index/web/botones/flecha-derecha-blanca.webp",
        displayBuscar: "none",
        displayMenu: "Index",
        color: "blanco",
        Seccion: "Inferior",
        Cliente: headerState["Cliente"],
        BotonCatalogo: headerState["BotonCatalogo"],
      });
    } else if (
      (relativePositionC2 === 1 &&
        relativePositionC1 !== -0 &&
        relativePositionC3 !== -0) ||
      relativePositionC2 === -0
    ) {
      modificarRadioButtons({
        radio1:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio2: recursosUrlState + "/img/Index/web/botones/radioSeleccion.webp",
        radio3:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio4:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio5:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
      });
      modificarHeaderState({
        srcLogo: recursosUrlState + "/img/Header/logos/logo-blanco.webp",
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha:
          recursosUrlState +
          "/img/Index/web/botones/flecha-derecha-blanca.webp",
        displayBuscar: "none",
        displayMenu: "Index",
        color: "blanco",
        Seccion: "Superior",
        Cliente: headerState["Cliente"],
        BotonCatalogo: headerState["BotonCatalogo"],
      });
    } else if (
      (relativePositionC1 === 1 && relativePositionC2 !== -0) ||
      relativePositionC1 === -0
    ) {
      modificarRadioButtons({
        radio1: recursosUrlState + "/img/Index/web/botones/radioSeleccion.webp",
        radio2:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio3:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio4:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
        radio5:
          recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.webp",
      });
      modificarHeaderState({
        srcLogo: recursosUrlState + "/img/Header/logos/logo-blanco.webp",
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha:
          recursosUrlState +
          "/img/Index/web/botones/flecha-derecha-blanca.webp",
        displayBuscar: "in-line",
        displayMenu: "Index",
        color: "blanco",
        Seccion: "New",
        Cliente: headerState["Cliente"],
        BotonCatalogo: headerState["BotonCatalogo"],
      });
    }
  };

  //Mediante el parametro car se determina a que carousel se debe desplazar automaticamente.
  const onChangeValue = (car) => {
    switch (car) {
      case "1":
        scrollToC1();
        break;
      case "2":
        scrollToC2();
        break;
      case "3":
        scrollToC3();
        break;
      case "4":
        scrollToC4();
        break;
      case "5":
        scrollToC5();
        break;
      default:
        scrollToC1();
        break;
    }
  };

  //Mediante el parametro url se determina la url de respuesta sustituyendo  el 'webp' por el 'png'.
  const cambioPng = (url) => {
    return url.replace(/webp/g, "png");
  };
  const CambioProductos = (mercado) => {
    actualizarSessionStorage("TipoCliente", mercado);
    modificarHeaderState({
      srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
      srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
      srcFlecha:
        recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.png",
      displayBuscar: "in-line",
      displayMenu: "Index",
      color: "negro",
      Seccion: headerState["Seccion"],
      Cliente: mercado,
      BotonCatalogo: headerState["BotonCatalogo"],
    });

    modificarHeaderState({
      srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
      srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
      srcFlecha:
        recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.webp",
      displayBuscar: "in-line",
      displayMenu: "Index",
      color: "negro",
      Seccion: headerState["Seccion"],
      Cliente: mercado,
      BotonCatalogo: headerState["BotonCatalogo"],
    });
  };
  //Funcion que se ejecuta cada vez que se inicia este componente.
  //Si al iniciar la ruta del Index el campo de registro se encutra hablitado en true, este
  //se cambia a false y se actualiza tambien en el localStorage.
  useEffect(() => {
    CambioProductos("Minoristas");
    if (headerState["Cliente"] !== "") {
      modificarHeaderState({
        ...headerState,
        Cliente: "",
      });
      actualizarSessionStorage("TipoCliente", "");
    }
    if (clienteState["Registro"]) {
      actualizarSessionStorage(
        "Cliente",
        JSON.stringify({
          ...clienteState,
          Registro: false,
        })
      );
      modificarClienteState({
        ...clienteState,
        Registro: false,
      });
    }
  }, []);

  /* La vista del Index se compone de la siguiente estructura: 
            Carousel 1 (LO NUEVO)
            Carousel 2 (SUPERIOR)
            Carousel 3 (INFERIOR)
            Carousel 4 (ACCESORIOS)
            Carousel 5 (REDES)
        A cada carousel se le envia el correspondiente del state de carousels.
        Tambien se maneja el sistema de radioButtons.
    */
  return (
    <div
      className="contenedor"
      onScroll={() => revisar()}
      onLoad={() => revisar()}
    >
      <div ref={refCarousel1} id="car1">
        <Carousel
          carousel={carousels[0]}
          toCarouselDown={() => scrollToC2()}
          headerState={headerState}
          modificarHeaderState={modificarHeaderState}
          recursosUrlState={recursosUrlState}
          modificarRecursosUrlState={modificarRecursosUrlState}
          actualizarSessionStorage={actualizarSessionStorage}
        />
      </div>

      <div ref={refCarousel2} id="car2">
        <Carousel
          carousel={carousels[1]}
          toCarouselUp={() => scrollToC1()}
          toCarouselDown={() => scrollToC3()}
          headerState={headerState}
          modificarHeaderState={modificarHeaderState}
          recursosUrlState={recursosUrlState}
          modificarRecursosUrlState={modificarRecursosUrlState}
          actualizarSessionStorage={actualizarSessionStorage}
        />
      </div>

      <div ref={refCarousel3} id="car3">
        <Carousel
          carousel={carousels[2]}
          toCarouselUp={() => scrollToC2()}
          toCarouselDown={() => scrollToC4()}
          headerState={headerState}
          modificarHeaderState={modificarHeaderState}
          recursosUrlState={recursosUrlState}
          modificarRecursosUrlState={modificarRecursosUrlState}
          actualizarSessionStorage={actualizarSessionStorage}
        />
      </div>

      <div ref={refCarousel4} id="car4">
        <Carousel
          carousel={carousels[3]}
          toCarouselUp={() => scrollToC3()}
          toCarouselDown={() => scrollToC5()}
          headerState={headerState}
          modificarHeaderState={modificarHeaderState}
          recursosUrlState={recursosUrlState}
          modificarRecursosUrlState={modificarRecursosUrlState}
          actualizarSessionStorage={actualizarSessionStorage}
        />
      </div>

      <div ref={refCarousel5} id="car5">
        <Carousel
          carousel={carousels[4]}
          toCarouselUp={() => scrollToC4()}
          headerState={headerState}
          modificarHeaderState={modificarHeaderState}
          recursosUrlState={recursosUrlState}
          modificarRecursosUrlState={modificarRecursosUrlState}
          actualizarSessionStorage={actualizarSessionStorage}
        />
      </div>
      <div className="indicadores">
        <div>
          <img
            src={radio1}
            alt="radio1"
            draggable="false"
            onClick={() => onChangeValue("1")}
            style={
              radio1.indexOf("Seleccion") !== -1
                ? radioSeleccionado
                : radioSinSeleccionar
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(radio1);
            }}
          />
        </div>
        <div className="radioMovil">
          <img
            src={radio2}
            alt="radio2"
            draggable="false"
            onClick={() => onChangeValue("2")}
            style={
              radio2.indexOf("Seleccion") !== -1
                ? radioSeleccionado
                : radioSinSeleccionar
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(radio2);
            }}
          />
        </div>
        <div className="radioMovil">
          <img
            src={radio3}
            alt="radio3"
            draggable="false"
            onClick={() => onChangeValue("3")}
            style={
              radio3.indexOf("Seleccion") !== -1
                ? radioSeleccionado
                : radioSinSeleccionar
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(radio3);
            }}
          />
        </div>
        <div className="radioMovil">
          <img
            src={radio4}
            alt="radio4"
            draggable="false"
            onClick={() => onChangeValue("4")}
            style={
              radio4.indexOf("Seleccion") !== -1
                ? radioSeleccionado
                : radioSinSeleccionar
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(radio4);
            }}
          />
        </div>
        <div className="radioMovil">
          <img
            src={radio5}
            alt="radio5"
            draggable="false"
            onClick={() => onChangeValue("5")}
            style={
              radio5.indexOf("Seleccion") !== -1
                ? radioSeleccionado
                : radioSinSeleccionar
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(radio5);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
