import React, { useEffect } from "react";
import Footer from "../Footer/Footer";

const Ayuda = ({
  headerState,
  modificarHeaderState,
  ayudaState,
  modificarAyudaState,
  recursosUrlState,
  modificarRecursosUrlState,
}) => {
  //Se determina que opcion de ayuda es y se modifica el state de Ayuda.
  const opcionAyuda = (opcion) => {
    if (opcion === "Producto")
      modificarAyudaState({ Producto: true, Envio: false, Cambios: false });
    else if (opcion === "Envio")
      modificarAyudaState({ Producto: false, Envio: true, Cambios: false });
    else if (opcion === "Cambios")
      modificarAyudaState({ Producto: false, Envio: false, Cambios: true });
    else modificarAyudaState({ Producto: false, Envio: false, Cambios: false });
  };

  //Funciona que se ejecuta al iniciar un componente.
  //Si la barra de busqueda esta desplegada se oculta y se actualiza el state del Header.
  useEffect(() => {
    var header = JSON.parse(JSON.stringify(headerState));
    if (header["displayBuscar"] !== "none") {
      header["displayBuscar"] = "none";
      header["displayFlecha"] = "none";
      modificarHeaderState(header);
    }
  });

  return (
    <div>
      <div className="ayudaWeb">
        {!ayudaState["Producto"] &&
        !ayudaState["Envio"] &&
        !ayudaState["Cambios"] ? (
          <div className="centrar">
            <p className="FuenteBebas text-center tituloAyuda">Ayuda</p>
            <div className="lineaAyuda">
              <div className="FuenteBebas text-center">
                <img
                  className="SinDecoracion imagenAyuda"
                  src={recursosUrlState + "/img/Ayuda/Producto-negro.webp"}
                  alt="ProductoAyuda"
                  style={{ cursor: "pointer" }}
                  onClick={() => opcionAyuda("Producto")}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      recursosUrlState + "/img/Ayuda/Producto-negro.png";
                  }}
                ></img>
                <div
                  className="centrar tituloOpcion"
                  style={{ width: "100px" }}
                  onClick={() => opcionAyuda("Producto")}
                >
                  <p style={{ cursor: "pointer" }}>Producto</p>
                </div>
              </div>
              <div className="FuenteBebas text-center">
                <img
                  className="SinDecoracion imagenAyuda"
                  src={recursosUrlState + "/img/Ayuda/Envio-negro.webp"}
                  alt="ProductoAyuda"
                  style={{ cursor: "pointer" }}
                  onClick={() => opcionAyuda("Envio")}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      recursosUrlState + "/img/Ayuda/Envio-negro.png";
                  }}
                ></img>
                <div
                  className="centrar tituloOpcion"
                  style={{ width: "80px" }}
                  onClick={() => opcionAyuda("Envio")}
                >
                  <p style={{ cursor: "pointer" }}>Envio</p>
                </div>
              </div>
              <div className="FuenteBebas text-center">
                <img
                  className="SinDecoracion imagenAyuda"
                  src={recursosUrlState + "/img/Ayuda/Cambio-negro.webp"}
                  alt="ProductoAyuda"
                  style={{ cursor: "pointer" }}
                  onClick={() => opcionAyuda("Cambios")}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      recursosUrlState + "/img/Ayuda/Cambio-negro.png";
                  }}
                ></img>
                <div
                  className="centrar tituloOpcion"
                  style={{ width: "100px" }}
                  onClick={() => opcionAyuda("Cambios")}
                >
                  <p style={{ cursor: "pointer" }}>Cambios y devoluciones</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {ayudaState["Producto"] &&
        !ayudaState["Envio"] &&
        !ayudaState["Cambios"] ? (
          <div className="centrar">
            <p className="FuenteBebas text-center tituloAyuda">Producto</p>
            <div className="row">
              <div className="FuenteBebas text-center">
                <img
                  className="SinDecoracion imagenAyuda"
                  src={recursosUrlState + "/img/Ayuda/Producto-negro.webp"}
                  alt="ProductoAyuda"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      recursosUrlState + "/img/Ayuda/Producto-negro.png";
                  }}
                ></img>
                <div className="centrar textoAyuda">
                  <p className="FuenteHelvetica" style={{ cursor: "pointer" }}>
                    En Basic Couture puedes encontrar una amplia gama de prendas de
                    materiales naturales, artificiales y sintéticos. Tanto en
                    dkda.com.co como en cada producto tendrás información
                    detalla sobre la composición de cada artículo.
                  </p>
                </div>
              </div>
            </div>
            <div className=" row volver" onClick={() => opcionAyuda("")}>
              <p className="FuenteBebas" style={{ cursor: "pointer" }}>
                Volver
              </p>
              <img
                className="botonIzquierda"
                src={
                  recursosUrlState +
                  "/img/Index/web/botones/flecha-izquierda-negro.webp"
                }
                alt="flechaIzquierda"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    recursosUrlState +
                    "/img/Index/web/botones/flecha-izquierda-negro.png";
                }}
              ></img>
            </div>
            <div className="centrar">
              <div className="distanciaAyuda">
                <img
                  className="SinDecoracion imagenAlgodon"
                  src={recursosUrlState + "/img/Ayuda/Algodon-negro.webp"}
                  alt="Algodon"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      recursosUrlState + "/img/Ayuda/Algodon-negro.png";
                  }}
                ></img>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {!ayudaState["Producto"] &&
        ayudaState["Envio"] &&
        !ayudaState["Cambios"] ? (
          <div>
            <div className="centrar">
              <p className="FuenteBebas text-center tituloAyuda">Envio</p>
              <div className="row">
                <div className="FuenteBebas text-center centrar">
                  <img
                    className="SinDecoracion imagenAyuda"
                    src={recursosUrlState + "/img/Ayuda/Envio-negro.webp"}
                    alt="EnvioAyuda"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        recursosUrlState + "/img/Ayuda/Envio-negro.png";
                    }}
                  ></img>
                  <div className="centrar textoAyuda">
                    <p
                      className="FuenteHelvetica"
                      style={{ cursor: "pointer" }}
                    >
                      En Basic Couture las opciones de envío pueden varias en función
                      del destino, la hora de tu compra y la disponibilidad de
                      los artículos. En el momento de procesar tu compra, te
                      mostraremos los métodos de envío disponibles, el costo y
                      la fecha de entrega de tu pedido. Realizamos los envíos
                      por medio de las principales transportadoras nacionales.
                    </p>
                  </div>
                </div>
              </div>
              <div className="centrar" style={{ marginTop: "50px" }}>
                <img
                  className="SinDecoracion imagenTransportadoras"
                  src={recursosUrlState + "/img/Ayuda/Envia.webp"}
                  alt="Algodon"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = recursosUrlState + "/img/Ayuda/Envia.png";
                  }}
                ></img>
                <img
                  className="SinDecoracion imagenTransportadoras"
                  src={recursosUrlState + "/img/Ayuda/InterRapidisimo.webp"}
                  alt="Algodon"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      recursosUrlState + "/img/Ayuda/InterRapidisimo.png";
                  }}
                ></img>
                <img
                  className="SinDecoracion imagenTransportadoras"
                  src={recursosUrlState + "/img/Ayuda/Servientrega.webp"}
                  alt="Algodon"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      recursosUrlState + "/img/Ayuda/Servientrega.png";
                  }}
                ></img>
                <img
                  className="SinDecoracion imagenTransportadorasLargas"
                  src={recursosUrlState + "/img/Ayuda/Deprisa.webp"}
                  alt="Algodon"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = recursosUrlState + "/img/Ayuda/Deprisa.png";
                  }}
                ></img>
                <img
                  className="SinDecoracion imagenTransportadorasLargas"
                  src={recursosUrlState + "/img/Ayuda/Saferbo.webp"}
                  alt="Algodon"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = recursosUrlState + "/img/Ayuda/Saferbo.png";
                  }}
                ></img>
              </div>
            </div>
            <div className=" row volver" onClick={() => opcionAyuda("")}>
              <p className="FuenteBebas" style={{ cursor: "pointer" }}>
                Volver
              </p>
              <img
                className="botonIzquierda"
                src={
                  recursosUrlState +
                  "/img/Index/web/botones/flecha-izquierda-negro.webp"
                }
                alt="flechaIzquierda"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    recursosUrlState +
                    "/img/Index/web/botones/flecha-izquierda-negro.png";
                }}
              ></img>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {!ayudaState["Producto"] &&
        !ayudaState["Envio"] &&
        ayudaState["Cambios"] ? (
          <div>
            <div className="centrar">
              <p className="FuenteBebas text-center tituloAyuda">
                Cambios y devoluciones
              </p>
              <div className="row">
                <div className="FuenteBebas centrar text-center">
                  <img
                    className="SinDecoracion imagenAyuda"
                    src={recursosUrlState + "/img/Ayuda/Cambio-negro.webp"}
                    alt="ProductoAyuda"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        recursosUrlState + "/img/Ayuda/Cambio-negro.png";
                    }}
                  ></img>
                  <div className="centrar textoAyuda">
                    <p
                      className="FuenteHelvetica"
                      style={{ cursor: "pointer" }}
                    >
                      Si deseas realizar la devolución de un artículo, solo
                      podrás hacerlo al siguiente día hábil de haber recibido tu
                      pedido, de esta forma garantizamos que nuestros productos
                      no han sido usados ni exhibidos en ninguna tienda antes de
                      volver con nosotros.
                    </p>
                  </div>
                  <div className="centrar textoAyuda">
                    <p
                      className="FuenteHelvetica"
                      style={{ cursor: "pointer" }}
                    >
                      En el caso en que la razón de la devolución sea por una
                      imperfección en el producto, nosotros asumiremos el costo
                      del envío de este. En un caso contrario, el cliente deberá
                      asumir este costo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row volver" onClick={() => opcionAyuda("")}>
              <p className="FuenteBebas" style={{ cursor: "pointer" }}>
                Volver
              </p>
              <img
                className="botonIzquierda"
                src={
                  recursosUrlState +
                  "/img/Index/web/botones/flecha-izquierda-negro.webp"
                }
                alt="flechaIzquierda"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    recursosUrlState +
                    "/img/Index/web/botones/flecha-izquierda-negro.png";
                }}
              ></img>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="textoFooter">
          <p className="FuenteHelvetica text-center">Basic Couture</p>
          <p className="FuenteHelvetica text-center">
            Somos una marca Colombiana para los amantes al diseño, desde el año
            2013 hemos brindado a nuestros clientes prendas con los más altos
            estándares de calidad y valor agregado. Somos tendencia y revolución
            en la industria textil . ¡No vendemos ropa, vendemos diseños
            plasmados sobre prendas de vestir!
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Ayuda;
