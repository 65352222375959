import { useState, useEffect } from "react"
import "./static/Descuentos.css"
import axios from "axios"
import swal from "sweetalert";

const Descuentos = ({
  apiUrlState,
}) => {

  const [descuentos, setDescuentos] = useState({
    Mayorista: 0,
    Minorista: 0,
  });

  const [colecciones, setColecciones] = useState([]);

  const modificarDescuento = (e, mod) =>{
    if(e === "Mayorista" && mod === "add" && descuentos.Mayorista < 100) {
      setDescuentos({
        Mayorista: descuentos.Mayorista += 5,
        Minorista: descuentos.Minorista,
      });
    } else if (e === "Minorista" && mod === "add" && descuentos.Minorista < 100) {
      setDescuentos({
        Mayorista: descuentos.Mayorista,
        Minorista: descuentos.Minorista +=5,
      });
    } else if (e === "Mayorista" && mod === "sub" && descuentos.Mayorista > 0) {
      setDescuentos({
        Mayorista: descuentos.Mayorista -=5,
        Minorista: descuentos.Minorista,
      });
    } else if (e === "Minorista" && mod === "sub" && descuentos.Minorista > 0) {
      setDescuentos({
        Mayorista: descuentos.Mayorista,
        Minorista: descuentos.Minorista -=5,
      });
    }
  }

  const activarDescuentosAuto = async () => {
    const url = `${apiUrlState}/Api/Inventario/ActivarDescuentosAuto`;
    axios.get(url)
    .then((response)=>{
      swal(response.data.message, {
        icon: "info",
      });
    });
  }

  const desactivarDescuentosAuto = async () => {
    console.log("Desactivando descuentos");
    const url = `${apiUrlState}/Api/Inventario/DesactivarDescuentosAuto`;
    axios.get(url)
    .then((response)=>{
      swal(response.data.message, {
        icon: "info",
      });
    });
  }

  const activarDescuentosColeccion = async(e) => {
    e.preventDefault();
    const Coleccion = e.target.Coleccion.value;
    const Bodega = e.target.Bodega.value;
    const DescuentoMayorista = e.target.DctoMayorista.value;
    const DescuentoMinorista = e.target.DctoMinorista.value;
    // Realizar peticion 
    const url = `${apiUrlState}/Api/Inventario/DescuentosColeccion`;
    await axios.post(url, {
        Coleccion,
        Bodega, 
        DescuentoMayorista,
        DescuentoMinorista,
      }
    ).then((response)=>{
      console.log(response)
      swal(response.data.message, {
        icon: "info",
      });
    })

  }

  const desactivarDescuentosColeccion = async(e) => {
    e.preventDefault();
    console.log(e, e.target.Coleccion.value)
    const Coleccion = e.target.Coleccion.value;
    const Bodega = e.target.Bodega.value;
    const DescuentoMayorista = 0;
    const DescuentoMinorista = 0;
    // Realizar peticion 
    const url = `${apiUrlState}/Api/Inventario/DescuentosColeccion`;
    await axios.post(url, {
        Coleccion,
        Bodega, 
        DescuentoMayorista,
        DescuentoMinorista,
      }
    ).then((response)=>{
      console.log(response)
    })
  }

  const getColecciones = async() => {
    const url = `${apiUrlState}/Api/Inventario/GetColecciones`;
    await axios.get(url)
    .then((response)=>{
      setColecciones(response.data.data);
    });
  }
  
  useEffect(() => {
    getColecciones();
  }, [])
  

  return (
    <>
      <div className="container" style={{marginTop: "7rem"}}>
        <div>
          <h1>Descuentos automáticos</h1>
          <p>Estos descuentos se asignarán de acuerdo a la fecha de creación del producto.</p>
          <button
            type="button"
            className="btn btn-dark"
            onClick={()=>{ activarDescuentosAuto() }}
          >Activar</button>
          <button
            type="button"
            className="btn btn-dark"
            onClick={()=>{ desactivarDescuentosAuto() }}
          >Desactivar</button>
        </div>

        <div style={{marginTop: "5rem"}}>
          <form onSubmit={(e)=>{activarDescuentosColeccion(e)}}>
            <h1>Descuentos por colección</h1>
            <p>Estos descuentos se asignarán a los productos de la colección y bodega seleccionada.</p>
            <div className="div_btn">
              <div>
                <select
                id="coleccion"
                  className="form-control"
                  style={{width:"18%"}}
                  name="Coleccion"
                >
                  {/* <option value="00" disabled>Seleccione la colección</option> */}
                  {colecciones.map((item, index)=> (
                    <option
                    key={index}
                    value={item}>{item}</option>
                  ))}
                </select>
                <label htmlFor="coleccion">Colección</label>
              </div>
              <div>
                <select
                  id="bodega"
                  className="form-control"
                  style={{width:"18%"}}
                  name="Bodega"
                >
                  <option value="03">Principal</option>
                  <option value="06">Preventa</option>
                </select>
                <label htmlFor="bodega">Bodega</label>
              </div>
            </div>
            <div>
              <div className="">
                <div style={{display: "flex"}}>
                  <button
                    type="button"
                    className="btn-add" 
                    onClick={()=>{modificarDescuento("Mayorista", "sub")}}
                  >-</button>
                  <input 
                    id="mayorista"
                    type="number" 
                    className="input_btn"
                    name="DctoMayorista"
                    value={descuentos.Mayorista}
                    readOnly
                  />
                  <button
                    type="button"
                    className="btn-add"
                    onClick={(e)=>{modificarDescuento("Mayorista", "add")}}
                  >+</button>
                </div>
                <label htmlFor="mayorista">Descuento Mayorista</label>
              </div>
              <div className="minorista">
                <div style={{display: "flex"}}>
                  <button
                    type="button"
                    className="btn-add"
                    onClick={()=>{modificarDescuento("Minorista", "sub")}}
                  >-</button>
                  <input
                    id="minorista"
                    type="number" 
                    className="input_btn"
                    name="DctoMinorista"
                    value={descuentos.Minorista}
                    readOnly
                  />
                  <button
                    type="button"
                    className="btn-add"
                    onClick={()=>{modificarDescuento("Minorista", "add")}}
                  >+</button>
                </div>
                <label htmlFor="">Descuento Minorista</label>
              </div>
              <div style={{marginTop: "20px"}}>
                <button 
                  type="submit"
                  className="btn btn-dark"
                >Aplicar Descuento</button>
                {/* <button
                  type="button"
                  className="btn btn-dark"
                  onClick={()=>{desactivarDescuentosColeccion()}}
                >Desactivar
                </button> */}
              </div>
            </div>
          </form>
        </div>

      </div>
    </>
  )
}

export default Descuentos;