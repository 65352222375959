//Zona de importacion de componentes externos.
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";

//Zona de importacion de componentes internos.
import Producto from "./Producto";
import Filtros from "./Filtros";
import ProductoV2 from "./ProductoV2";
import CompraRapida from "./components/ModalCompra";

/** Importacion de servicios */
import apiFacebook from "../../services/apiFacebook";
import { actualizarEventId } from "../../global/functions";

//Zona de importacion de Css.
import "./static/css/Mercado/Mercado.css";
import "./static/css/Mercado/Mercado_MovilGrande.css";
import "./static/css/Mercado/Mercado_MovilPequeño.css";
import "./static/css/Mercado/Mercado_MovilHorizontal.css";
import "./static/css/Mercado/Mercado_Web.css";
import { useParams } from "react-router";
import Footer from "../Footer/Footer";
import NotFoundComponent from "./components/NotFound";
import { useCategContext } from "../../providers/CategoriasProviders";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

const Mercado = ({
  ViewDetalle,
  headerState,
  modificarHeaderState,
  filtrosState,
  modificarFiltrosState,
  productosState,
  modificarProductosState,
  tallasState,
  modificarTallasState,
  coloresState,
  modificarColoresState,
  marcasState,
  modificarMarcasState,
  medidasState,
  modificarMedidasState,
  carrito,
  modificarCarritoState,
  cliente,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  // clienteState,
  // modificarClienteState,
  categoriasState,
  modificarCategoriasState,
  CatalogoState,
  actualizarSessionStorage,
  rutaDinamica = true,
  subCategoriasState,
  modificarSubCategoriasState,
  refNoCatalogoState,
  modificarRefNoCatalogoState,
  vendedor,
  modificarVendedorState,
  Bodega,
  setBodega,
  BodegaCatalogos,
  setBodegaCatalogos,
  checkedProductos,
  setCheckedProductos,
}) => {
  const { clienteState, modificarClienteState } = useGlobalContext();

  // ReactPixel.track("HomeMinoristas", "ViewContent");
  /** Envia el evento de visita a la pagina web de minoristas a facebook */
  const enviarEventoFacebook = async () => {
    const event_id = actualizarEventId();
    const userIP = await apiFacebook.get();
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `hmin-${event_id}`,
      event_name: "HomeMinoristas",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0"
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }
  //Esta constante hace referencia al primer producto de la slista de productos actual.
  const prod1 = useRef(null);
  let { Categoria, Genero } = useParams();

  const categorias = useCategContext();
  // console.log("Context", categorias)

  /**Productos agrpados por referencia */
  const [productosByRef, setProductosByRef] = useState([]);

  /** Modal de compra rapida */
  const [activado, setActivado] = useState(false);

  /** Producto seleccionado para mostrar en modal */
  const [productoCompra, setProductoCompra] = useState(null)


  /** Busca la categoria que cumple con los parametros (Categoria, Genero)
   *  para extraer la informacion completa
   */
  const obtenerDatosCategoria = (categoria, genero) => {
    const data = categorias.find(categ => categ[8] === categoria && categ[7] === genero);
    return data;
  }

  /** Obtiene solo los ids de la lista de marcas */
  const getListaMarcas = (Marcas) => {
    let listaMarcas = [];
    for(const marca of Marcas) {
      listaMarcas.push(marca.Marca)
    }
    return listaMarcas;
  }

  /** Agrupa los productos por referencia */
  const groupBy = (array, keyFunction) => {
    return array.reduce((result, item) => {
      const key = keyFunction(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, {});
  }

  /** Retorna los parametros de consulta de productos segun
   *  la categoria seleccionada  */
  const RutaLanding = async () => {
    // console.log("LOGGG", Categoria, Genero)
    /** Consulta general de productos en Preventa */
    if (window.location.pathname === "/Preventa/Catalogo") {
      return {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    const dataConsulta = obtenerDatosCategoria(Categoria, Genero);
    if (dataConsulta) {
      /** Para las mismas categorias del home */
      const Ccategorias = dataConsulta[2];
      const Csubcategorias = dataConsulta[3];
      const Cmarcas = dataConsulta[4];
      const Cgeneros = dataConsulta[5];
      const Cnuevos = dataConsulta[6];
      const Cdescuentos = dataConsulta[9];
  
      const sub = Csubcategorias.length == 0 ? "" : Csubcategorias[0].Subcategoria;
      const marc = getListaMarcas(Cmarcas);
  
      let objCateg = { ...categoriasState };
      if(Ccategorias.length > 0 && Cgeneros.length > 0) {
        if(Cgeneros[0].Genero === "607ed653521b1c0910a581b8") {
            objCateg = {
              ...categoriasState,
              Hombre: Ccategorias[0].Categoria,
            }
        } else {
          objCateg = {
            ...categoriasState,
            Mujer: Ccategorias[0].Categoria,
          }
        }
      }
      await Promise.all([
        modificarFiltrosState({
          Genero: Cgeneros.length > 0? Cgeneros[0].Genero : "",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        
        modificarCategoriasState(objCateg),
        modificarSubCategoriasState(sub),
        modificarMarcasState(marc),
      ]);
  
      return {
        Categorias: Ccategorias,
        Generos: Cgeneros,
        Marcas: Cmarcas,
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: Csubcategorias,
        ParametroOrdenamiento: "",
        ProductosNuevos: Cnuevos,
        Descuentos: Cdescuentos,
        Todo: false,
      };

    } else if(Categoria === "Nuevo") {
      /** Para buscar los productos nuevos */
      await Promise.all([
        modificarFiltrosState({
          Genero: "",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        
        
        modificarSubCategoriasState(""),
        modificarMarcasState([]),
      ]);
      return {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: true,
        Descuentos: false,
        Todo: false,
      };
    } else if(Categoria === "Descuento") {
      // Para buscar los productos con descuento
      await Promise.all([
        modificarFiltrosState({
          Genero: "",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        
        modificarCategoriasState({
          Hombre: null,
          Mujer: null,
          Niño: null,
        }),
        modificarSubCategoriasState(""),
        modificarMarcasState([]),
      ]);
      return {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: true,
        Todo: false,
      };
    } else {
      /** Para las categorias adicionales que no estan definidas en el home
       *  Pero si en la base de datos */
      const datosConsulta = await getDataCategGene();
      let objCateg = { ...categoriasState }
      if (Genero === "Hombre") {
        objCateg = {
          ...categoriasState,
          Hombre: datosConsulta.Categorias[0].Categoria,
        }
      } else if(Genero === "Mujer") {
        objCateg = {
          ...categoriasState,
          Mujer: datosConsulta.Categorias[0].Categoria,
        }
      }
      await Promise.all([
        modificarFiltrosState({
          Genero: datosConsulta.Generos[0].Genero,
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        
        modificarCategoriasState(objCateg),
        modificarSubCategoriasState(""),
        modificarMarcasState([]),
      ]);
      return datosConsulta;
    }
  }

  /** Obtiene los datos de la categoria seleccionada en el filtro
   *  para realizar la consulta de productos
   */
  const getCategorias = async () => {
    let categoriaEncontrada = []
    const response = await axios.post(apiUrlState + "/Api/Categoria/ObtenerCategorias", {
      data: 
      [
        { Estado: true }
      ]
    })
    if (!response.data[0].error && Categoria) {
      const listaCategorias = response.data[0].data;
      const busquedaTemp = listaCategorias.find( categ => categ.Categoria == Categoria.toUpperCase());
      categoriaEncontrada = [{ Categoria: busquedaTemp._id }]
      return categoriaEncontrada;
    };
  }

  /** Obtiene los datos del genero seleccionado en el filtro
   *  para realizar la consulta de productos
   */
  const getGeneros = async () => {
    let generoEncontrado = [];
    const response = await axios.post(apiUrlState + "/Api/Genero/ObtenerGeneros", {
      data: {},
    })
    if (!response.data.error && Genero) {
      const listaGeneros = response.data.data;
      const busquedaTemp = listaGeneros.find( gen => gen.Genero === Genero.toUpperCase());
      generoEncontrado = [{ Genero: busquedaTemp._id }]
      return generoEncontrado;
    }
  }

  /** Genera el objeto de busqueda para las categorias de los filtros */
  const getDataCategGene = async() => {
    const categoriasConsulta = await getCategorias();
    const generosConsulta = await getGeneros();
    return {
      Categorias: categoriasConsulta,
      Generos: generosConsulta,
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "Marca",
      ProductosNuevos: false,
      Descuentos: false,
      Todo: false,
    };
  }

  //Ruta landing -> Replanteado para que se mas dinámico
  const RutaLanding2 = async () => {
    if (Categoria === "Camiseta" && Genero === "Mujer") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "607ed63e521b1c0910a581aa",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581aa" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [
          { Marca: "607ed64c521b1c0910a581b3" },
          { Marca: "64f8df51ab8f6199b7154137" }
        ],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Basica" && Genero === "Hombre") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b8",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Hombre: "607ed63e521b1c0910a581aa",
        }),
        modificarSubCategoriasState("607ed65a521b1c0910a581bd"),
        modificarMarcasState(["607ed64c521b1c0910a581b3"]),
      ]);

      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581aa" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b8" }],
        // Marcas: [{ Marca: "607ed64c521b1c0910a581b3" }],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        // Subcategoria: [{ Subcategoria: "607ed65a521b1c0910a581bd" }],
        Subcategoria: [
          { Subcategoria: "607ed65a521b1c0910a581bd" }, // Basicas
          { Subcategoria: "65a53552e8989878c228d032" }  // General
        ],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Blusa" && Genero === "Mujer") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "651aec4251072238a0969749",
        }),
        modificarSubCategoriasState("607ed65a521b1c0910a581bd"),
        modificarMarcasState(["64f8df51ab8f6199b7154137"]),
      ]);

      return {
        Categorias: [
          { Categoria: "651aec4251072238a0969749" }, // Blusa
          { Categoria: "655bad5a1cdc932b9e974a01" }, // Body
          { Categoria: "6583763e8dfd4f911a2d0388" }, // Seamless
        ],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [{ Marca: "64f8df51ab8f6199b7154137" }],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [
          { Subcategoria: "607ed65a521b1c0910a581bd" }, // Basica
          { Subcategoria: "65a53552e8989878c228d032" }  // General
        ],
        ParametroOrdenamiento: "",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Seamless" && Genero === "Mujer") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "6583763e8dfd4f911a2d0388",
        }),
        modificarSubCategoriasState("607ed65a521b1c0910a581bd"),
        modificarMarcasState(["64f8df51ab8f6199b7154137"]),
      ]);

      return {
        Categorias: [
          { Categoria: "6583763e8dfd4f911a2d0388" }, // Tubular
        ],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [{ Marca: "64f8df51ab8f6199b7154137" }],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [
          { Subcategoria: "607ed65a521b1c0910a581bd" }, // Basica
          { Subcategoria: "65a53552e8989878c228d032" }  // General
        ],
        ParametroOrdenamiento: "",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "CamisetaEsDk" && Genero === "Mujer") {
      // Incuidas en Camisetas Estampadas
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "607ed63e521b1c0910a581aa",
        }),
        modificarSubCategoriasState("607ed65a521b1c0910a581bf"),
        modificarMarcasState(["64f8df51ab8f6199b7154137"]),
      ]);

      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581aa" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [{ Marca: "64f8df51ab8f6199b7154137" }],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [{ Subcategoria: "607ed65a521b1c0910a581bf" }],
        ParametroOrdenamiento: "",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Vestido" && Genero === "Mujer") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: true,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "65578d4701bf5211b63d14bc",
        }),
        modificarSubCategoriasState("607ed65a521b1c0910a581bd"),
        modificarMarcasState(["64f8df51ab8f6199b7154137"]),
      ]);

      return {
        Categorias: [{ Categoria: "65578d4701bf5211b63d14bc" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [{ Marca: "64f8df51ab8f6199b7154137" }],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [{ Subcategoria: "607ed65a521b1c0910a581bd" }],
        ParametroOrdenamiento: "",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Lycra" && Genero === "Mujer") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "6525a7906b44bb8bf3323210",
        }),
        modificarSubCategoriasState("607ed65a521b1c0910a581bd"),
        modificarMarcasState(["64f8df51ab8f6199b7154137"]),
      ]);

      return {
        Categorias: [{ Categoria: "6525a7906b44bb8bf3323210" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [{ Marca: "64f8df51ab8f6199b7154137" }],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [{ Subcategoria: "607ed65a521b1c0910a581bd" }],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }    
    if (Categoria === "Estampada" && Genero === "Hombre") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b8",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
          Descuentos: false,
          Todo: false,
        }),
        modificarCategoriasState({
          ...categoriasState,
          Hombre: "607ed63e521b1c0910a581aa",
        }),
        modificarSubCategoriasState("607ed65a521b1c0910a581bf"),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581aa" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b8" }],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        // Subcategoria: [{ Subcategoria: "607ed65a521b1c0910a581bf" }],
        Subcategoria: [
          { Subcategoria: "607ed65a521b1c0910a581bf" }, // Estampadas
          { Subcategoria: "65a53552e8989878c228d032" }  // General
        ],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Polo" && Genero === "Hombre") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b8",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Hombre: "607ed63f521b1c0910a581b1",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63f521b1c0910a581b1" }],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Gorra" && Genero === "Unisex") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b8",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Hombre: "607ed63e521b1c0910a581ac",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581ac" }],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Pantalonetas" && Genero === "Hombre") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b8",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Hombre: "607ed63e521b1c0910a581b0",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581b0" }],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Hoodie" && Genero === "Hombre") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b8",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Hombre: "607ed63e521b1c0910a581ad",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581ad" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b8" }],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Hoodie" && Genero === "Mujer") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "607ed63e521b1c0910a581ad",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581ad" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Jogger" && Genero === "Hombre") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b8",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Hombre: "607ed63e521b1c0910a581af",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581af" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b8" }],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Jogger" && Genero === "Mujer") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581b9",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
        modificarCategoriasState({
          ...categoriasState,
          Mujer: "607ed63e521b1c0910a581af",
        }),
      ]);
      return {
        Categorias: [{ Categoria: "607ed63e521b1c0910a581af" }],
        Generos: [{ Genero: "607ed653521b1c0910a581b9" }],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Camiseta" && Genero === "Niño") {
      await Promise.all([
        modificarFiltrosState({
          Genero: "607ed653521b1c0910a581ba",
          Categoria: "NoNew",
          CambioFiltro: false,
          CambioCategoria: false,
          filtroResumido: true,
          posicionFiltros: "-100%",
          width: "0px",
        }),
      ]);
      return {
        Categorias: [],
        Generos: [{ Genero: "607ed653521b1c0910a581ba" }],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Nuevo" && Genero === "New") {
      return {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: true,
        Descuentos: false,
        Todo: false,
      };
    }
    if (Categoria === "Nuevo" && Genero === "Descuento") {
      return {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "Marca",
        ProductosNuevos: false,
        Descuentos: true,
        Todo: false,
      };
    }
    if (window.location.pathname === "/Preventa/Catalogo") {
      if (!localStorage.getItem("Vendedor")) {
        window.location.href = "/Preventa"
      } else {
        return {
          Categorias: [],
          Generos: [],
          Marcas: [],
          Medidas: [],
          Tallas: [],
          Colores: [],
          Subcategoria: [],
          ParametroOrdenamiento: "Marca",
          ProductosNuevos: false,
          Descuentos: false,
          Todo: false,
          Bodega: 2,
        };
      }
    }

    // Revisar este return si se muestran productos que no deberian
    return {
      Categorias: [],
      Generos: [],
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "Marca",
      ProductosNuevos: false,
      Descuentos: true,
      Todo: false,
    };
  };
  //!Modificar variable "Seccion" con el parametro de la ruta rutaDinamica
  //Se hace una consulta a la api para obtener los productos segun la seccion ingresada por parametro en la ruta '/Inventario/ConsultarProductos/{Seccion}'
  const obtenerSeccion = async (Seccion) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }
    let data = {
      Categorias: [],
      Generos: [],
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "",
      ProductosNuevos: false,
      Descuentos: false,
      Todo: false,
    };
    if(window.location.pathname == "/Admin/GeneradorDeCatalogos") {
      data.Bodega = BodegaCatalogos;
    } else {
      data.Bodega = Bodega;
    }
    if (rutaDinamica) {
      const cateGene = await RutaLanding();
      // console.log(rutaDinamica, "RUTA", cateGene)
      data = cateGene;
      data.Origen = "Mercado";
      data.Bodega = Bodega;
    }
    await modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    await axios
      .post(
        apiUrlState + "/Api/Inventario/GetProductos",
        {
          data,
        },
        {}
      )
      .then((response) => {
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: true },
          response.data.data,
        ]);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: false },
          [],
        ]);
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  //Mediante la referenca del primer producto se desplaza el scroll de forma suave hacia el.
  const irInicio = () => {
    if (prod1["current"] !== null) {
      prod1.current.scrollIntoView({ behavior: "smooth" });
      modificarProductosState([
        {
          CambioSeccion: false,
          Busqueda: productosState[0]["Busqueda"],
          irInicio: false,
        },
        productosState[1],
      ]);
    }
  };

  //Funcion que se ejecuta cada vez que se inicia este componente.
  //Pregunta si hay que dirigirse al inicio de la lista de productos
  //Pregunta si hubo un cambio de seccion.
  //Pregunta si el header esta configurado en version Index, para cambiarloo a version Mercado y actualizar tambien el localStorage.
  //Si la sesion es de Mayoristas y no ha iniciado sesion, al intentar acceder a /Mercado se redirecciona automaticamente a /InicioSesion.
  //Si en la sesion actual esta activado el modo de registro, se cambia a 'false', tambien actualizando el localStorage.
  useEffect(() => {
    if (Categoria != undefined && Genero != undefined && vendedor == null) {
      obtenerSeccion(" ");
      modificarHeaderState({ ...headerState, Cliente: "Minoristas" });
    } else {
      if (Categoria)
        if (productosState[0]["irInicio"]) {
          irInicio();
        }
      if (productosState[0]["CambioSeccion"]) {
        if (headerState["Seccion"] === "") {
          obtenerSeccion("New");
        } else {
          obtenerSeccion(headerState["Seccion"]);
        }
      }
      if (headerState["displayMenu"] === "Index") {
        modificarFiltrosState({
          Genero: filtrosState["Genero"],
          Categoria: filtrosState["Categoria"],
          CambioFiltro: true,
          CambioCategoria: false,
          filtroResumido: false,
          posicionFiltros: "-100%",
          width: "0px",
        });
        const tipoClienteSession = localStorage.TipoCliente;
        modificarHeaderState({
          srcLogo: headerState["srcLogo"],
          srcBolsa: headerState["srcBolsa"],
          srcFlecha: headerState["srcFlecha"],
          displayBuscar: headerState["displayBuscar"],
          displayMenu: "Productos",
          color: "negro",
          Seccion: headerState["Seccion"],
          Cliente: tipoClienteSession,
          BotonCatalogo: CatalogoState,
        });
      }
      if (
        headerState["Cliente"] === undefined ||
        headerState["Cliente"] === "Mayoristas"
      ) {
        modificarHeaderState({
          srcLogo: headerState["srcLogo"],
          srcBolsa: headerState["srcBolsa"],
          srcFlecha: headerState["srcFlecha"],
          displayBuscar: headerState["displayBuscar"],
          displayMenu: "Productos",
          color: "negro",
          Seccion: headerState["Seccion"],
          Cliente: "Minoristas",
          BotonCatalogo: CatalogoState,
        });

        if (
          localStorage.TipoCliente !== "Minoristas" &&
          localStorage.TipoCliente !== "Mayoristas"
        )
          actualizarSessionStorage("TipoCliente", "Minoristas");
      }
      if (clienteState["Registro"]) {
        actualizarSessionStorage(
          "Cliente",
          JSON.stringify({
            ...clienteState,
            Registro: false,
          })
        );
        modificarClienteState({
          ...clienteState,
          Registro: false,
        });
      }
    }
    textoCirculo();
  }, []);

  useEffect(() => {
    if(productosState[1].length > 0) {
      const pAgrupados = groupBy(productosState[1], ({ RefBase }) => RefBase);
      setProductosByRef(pAgrupados);
    }
  
  }, [productosState])
  

  const textoCirculo = () => {
    const circulo = document.getElementById("circle");
    const circleArray = circulo.textContent.trimStart().split("");

    circulo.textContent = " ";
    for (var i = 0; i < circleArray.length; i++) {
      circulo.innerHTML +=
        '<span style="transform:rotate(' +
        (i - 8) * 12 +
        'deg)">' +
        circleArray[i] +
        "</span>";
    }
  };

  useEffect(() => {
    enviarEventoFacebook();
   }, [])

  //Estructura del mercado:
  //Componente de Filtros.
  //Lista de productos - (Si no hay ningun producto en la lista se muestra la imagen de 'No hay')
  return (
    <>
      <CompraRapida
      apiUrlState={apiUrlState}
      headerState={headerState}
      productoCompra={productoCompra}
      setProductoCompra={setProductoCompra}
      activado={activado}
      setActivado={setActivado}
      carrito={carrito}
      modificarCarritoState={modificarCarritoState}
      actualizarSessionStorage={actualizarSessionStorage}
      spinnerState={spinnerState}
      modificarSpinnerState={modificarSpinnerState}
      errorState={errorState}
      modificarErrorState={modificarErrorState}
      />
      <div className="MercadoMinoristas">
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
        />
        <a
          href="https://api.whatsapp.com/send?phone=573195123051&text=Hola 👋🏻 %0D%0AEstoy viendo sus productos en la página web para minoristas y tengo algunas dudas."
          className="float"
          target="_blank"
        >
          <i className="fa fa-whatsapp my-float"></i>
          <h1 id="circle">¿Necesitas Ayuda?</h1>
        </a>
        {/*Subir productos al catalogo por pixel */}

        {/* <div itemScope itemType="http://schema.org/Product">
          <meta itemProp="brand" content="Croatta" />
          <meta itemProp="name" content="Camiseta" />
          <meta itemProp="description" content="Mujer" />
          <meta itemProp="productID" content="Croatta00024" />
          <meta itemProp="url" content="https://www.basiccouture.com.co/Mercado" />
          <meta
            itemProp="image"
            content="https://hosting.basic.dekada.co/BasicCouture/img/Productos/Ref%20420CSTCM.webp"
          />
          <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
            <meta itemProp="price" content="7.99" />
            <meta itemProp="priceCurrency" content="USD" />
          </div>
        </div>
        <div>
          <meta property="og:title" content="Croatta" />
          <meta property="og:description" content="Mujer" />
          <meta property="og:url" content="https://www.basiccouture.com.co/Mercado" />
          <meta
            property="og:image"
            content="https://hosting.basic.dekada.co/BasicCouture/img/Productos/Ref%20420CSTCM.webp"
          />
          <meta property="product:brand" content="Croatta" />
          <meta property="product:availability" content="in stock" />
          <meta property="product:condition" content="new" />
          <meta property="product:price:amount" content="7.99" />
          <meta property="product:price:currency" content="USD" />
          <meta property="product:retailer_item_id" content="Croatta00024" />
          <meta property="product:item_group_id" content="fb_tshirts" />
        </div> */}

        <div id="FiltrosWeb">
          <div id="filtros">
            <Filtros
              actualizarSessionStorage={actualizarSessionStorage}
              apiUrlState={apiUrlState}
              CatalogoState={CatalogoState}
              categoriasState={categoriasState}
              subCategoriasState={subCategoriasState}
              coloresState={coloresState}
              errorState={errorState}
              filtrosState={filtrosState}
              headerState={headerState}
              marcasState={marcasState}
              medidasState={medidasState}
              modificarMedidasState={modificarMedidasState}
              modificarApiUrlState={modificarApiUrlState}
              modificarCategoriasState={modificarCategoriasState}
              modificarColoresState={modificarColoresState}
              modificarErrorState={modificarErrorState}
              modificarFiltrosState={modificarFiltrosState}
              modificarHeaderState={modificarHeaderState}
              modificarMarcasState={modificarMarcasState}
              modificarProductosState={modificarProductosState}
              modificarRecursosUrlState={modificarRecursosUrlState}
              modificarSpinnerState={modificarSpinnerState}
              modificarTallasState={modificarTallasState}
              productosState={productosState}
              recursosUrlState={recursosUrlState}
              spinnerState={spinnerState}
              tallasState={tallasState}
              refNoCatalogoState={refNoCatalogoState}
              modificarRefNoCatalogoState={modificarRefNoCatalogoState}
              vendedor={vendedor}
              modificarVendedorState={modificarVendedorState}
              Bodega={Bodega}
              setBodega={setBodega}
              BodegaCatalogos={BodegaCatalogos}
              setBodegaCatalogos={setBodegaCatalogos}
              checkedProductos={checkedProductos}
              setCheckedProductos={setCheckedProductos}
            />
          </div>
        </div>

        {vendedor? (
        <div id={productosState[1].length > 0 ? "fila" : null }>
          {productosState[1].length > 0 ? (
            productosState[1].map((producto, index) =>
              index === 0 ? (
                <Producto
                  key={producto["Codigo"]}
                  cliente={cliente}
                  index={index}
                  producto={producto}
                  carrito={carrito}
                  modificarCarritoState={modificarCarritoState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  errorState={errorState}
                  modificarErrorState={modificarErrorState}
                  spinnerState={spinnerState}
                  modificarSpinnerState={modificarSpinnerState}
                  apiUrlState={apiUrlState}
                  modificarApiUrlState={modificarApiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  actualizarSessionStorage={actualizarSessionStorage}
                  ViewDetalle={ViewDetalle}
                  tallasState={tallasState}
                  modificarTallasState={modificarTallasState}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}  
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
              ) : (
                <Producto
                  key={producto["Codigo"]}
                  cliente={cliente}
                  index={index}
                  producto={producto}
                  carrito={carrito}
                  modificarCarritoState={modificarCarritoState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  errorState={errorState}
                  modificarErrorState={modificarErrorState}
                  spinnerState={spinnerState}
                  modificarSpinnerState={modificarSpinnerState}
                  apiUrlState={apiUrlState}
                  modificarApiUrlState={modificarApiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  actualizarSessionStorage={actualizarSessionStorage}
                  ViewDetalle={ViewDetalle}
                  tallasState={tallasState}
                  modificarTallasState={modificarTallasState}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}  
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
              )
            )
          ) : (            
            <div style={ spinnerState.Desplegar ? {display: "none"} : {display: "flex", justifyContent: "space-evenly", alignItems: "center", height: "100vh", textAlign: "center"}}>
              {headerState["displayMenu"] === "Index" ||
              productosState[0]["CambioSeccion"] !== false ||
              window.location.pathname == "/Admin/GeneradorDeCatalogos" ||
              window.location.pathname == "/Preventa/Catalogo" ? (
                 <NotFoundComponent redirectTo="/Mercado/Estampada/Hombre"/>
                  
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

        ) : (
          // Productos agrupados por referencia
          <div id={productosState[1].length > 0 ? "fila" : null }>
            {productosState[1].length > 0? (
              <>
                {Object.keys(productosByRef).map((referencia) => (
                  <ProductoV2
                  key={referencia}
                  productoCompra={productoCompra}
                  setProductoCompra={setProductoCompra}
                  activado={activado}
                  setActivado={setActivado}
                  cliente={cliente}
                  index={0}
                  producto={productosByRef[referencia]}
                  carrito={carrito}
                  modificarCarritoState={modificarCarritoState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  errorState={errorState}
                  modificarErrorState={modificarErrorState}
                  spinnerState={spinnerState}
                  modificarSpinnerState={modificarSpinnerState}
                  apiUrlState={apiUrlState}
                  modificarApiUrlState={modificarApiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  actualizarSessionStorage={actualizarSessionStorage}
                  ViewDetalle={ViewDetalle}
                  tallasState={tallasState}
                  modificarTallasState={modificarTallasState}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}  
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
                ))}
              </>
            ): (
              <div style={{height: "100vh", alignContent: "center"}}>
                <NotFoundComponent redirectTo="/Mercado/Estampada/Hombre"/>
              </div>
            )}

          </div>
        )}

        <Footer></Footer>
      </div>
    </>
  );
};

export default Mercado;
