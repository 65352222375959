import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./static/Landing.css";
import { useParams } from "react-router";
import axios from "axios";
import { $ } from "react-jquery-plugin";

const Landing = ({
  actualizarSessionStorage,
  modificarHeaderState,
  recursosUrlState,
  headerState,
  modificarlandingState,
  apiUrlState,
  SetLandingHeader,
}) => {
  const [Ruta, GetRuta] = useState({ Video: " ", Categoria: "", bool: true });
  const [Product, GetProduct] = useState([]);
  const [RutaL, GetRutaL] = useState({ Categoria: "" });
  const [carousels, Getcarousels] = useState({ carouselx: "carousel" });

  //

  const CambioProductos = (mercado) => {
    actualizarSessionStorage("TipoCliente", mercado);
    modificarHeaderState({
      srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
      srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
      srcFlecha:
        recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.png",
      displayBuscar: "in-line",
      displayMenu: "Index",
      color: "negro",
      Seccion: headerState["Seccion"],
      Cliente: mercado,
      BotonCatalogo: headerState["BotonCatalogo"],
    });
    /*$('.carousel').carousel({
            ride: true
          })*/
  };
  const activar = () => {
    $(".carousel").carousel({
      ride: true,
    });
    $(".carousel").carousel("next");
  };
  let { id, Genero } = useParams();

  /*const obtenerSeccion = async(Seccion) => {
        const getJWT = async(callback) => {
            await axios.post(apiUrlState+"/auth/get-token/", {
                "username": "CroattaWeb",
                "password": "Since2013"
            }).then(response => {
                callback(response.data);

                
            }).catch(function(err) {
            })
        }
        getJWT(async function(token){
            await axios.get(apiUrlState+"/Inventario/ConsultarProductos"+Seccion, {
            //...data
            }, {
                headers: {
                    "authorization": ("Bearer " + token["token"]) 
                }
            }).then(response => {
                
                //GetProduct(response.data)
            }).catch(function(err) {
                
            })
        })
    }*/
  //Ruta landing
  const RutaLanding = async () => {
    if (id === "Camiseta" && Genero === "Hombre") {
      return ["607ed63e521b1c0910a581aa", "607ed653521b1c0910a581b8"];
    }
    if (id === "Camiseta" && Genero === "Niño") {
      return ["607ed63e521b1c0910a581aa", "607ed653521b1c0910a581ba"];
    }
    if (id === "Camiseta" && Genero === "Mujer") {
      return ["607ed63e521b1c0910a581aa", "607ed653521b1c0910a581b9"];
    }
    if (id === "Gorra" && Genero === "Unisex") {
      return ["607ed63e521b1c0910a581ac", "607ed653521b1c0910a581bb"];
    }
    if (id === "Hoodie" && Genero === "Hombre") {
      return ["607ed63e521b1c0910a581ad", "607ed653521b1c0910a581b8"];
    }
    if (id === "Hoodie" && Genero === "Mujer") {
      return ["607ed63e521b1c0910a581ad", "607ed653521b1c0910a581b9"];
    }
    if (id === "Polo" && Genero === "Hombre") {
      return ["607ed63f521b1c0910a581b1", "607ed653521b1c0910a581b8"];
    }
    if (id === "Pantaloneta" && Genero === "Hombre") {
      return ["607ed63e521b1c0910a581b0", "607ed653521b1c0910a581b8"];
    }
    if (id === "Jogger" && Genero === "Hombre") {
      return ["607ed63e521b1c0910a581af", "607ed653521b1c0910a581b8"];
    }
    if (id === "Jogger" && Genero === "Mujer") {
      return ["607ed63e521b1c0910a581af", "607ed653521b1c0910a581b9"];
    }

    return ["", ""];
  };
  const obtenerSeccion2 = async (Seccion) => {
    let data = {
      Categorias: [],
      Generos: [],
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "",
      ProductosNuevos: false,
      Descuentos: false,
      Todo: false,
      Origen: "Landing"
    };
    const cateGene = await RutaLanding();
    data["Categorias"].push({ Categoria: cateGene[0] });
    data["Generos"].push({ Genero: cateGene[1] });
    data["ProductosNuevos"] = false;

    await axios
      .post(
        apiUrlState + "/Api/Inventario/GetProductos",
        {
          data,
        },
        {}
      )
      .then((response) => {
        GetProduct(response.data.data);
      })
      .catch(function (err) {
        console.log("Error");
      });
  };
  //position: absolute; transform: translate(-50%, 0%);
  /*const jqueryx =  () => {
        $('.carousel').carousel({
            ride: true
          })
        $('.carousel').carousel('prev');
       
    }*/
  const Condicion = () => {
    /*$(".carousel").carousel();
          $('.carousel').carousel({
            ride: "true"
          })
          $(".item").click(function(){
            $(".carousel").carousel(1);
          });*/

    GetRutaL({ Categoria: "/" + id + "/" + Genero });
    modificarlandingState({
      Display: "none",
      Posicion: "50%",
      positionlogo: "absolute",
      transform: "translate(-50%, 0%)",
    });
    obtenerSeccion2("");
    if (id === "Remarketing") {
      GetRuta({
        ...Ruta,
        Video: "https://www.youtube.com/embed/q3W2K772eqM",
      });
    }
    if (id === "Gorra") {
      var categGorra = id + "/Unisex";
      //obtenerSeccion("/"+id)
      //obtenerSeccion2("")
      GetRuta({
        Categoria: categGorra,
        Video: "https://www.youtube.com/embed/q3W2K772eqM",
      });
    }
    if (id === "Polo") {
      var categPolo = id + "/Hombre";
      //obtenerSeccion("/"+categPolo)
      GetRuta({
        Categoria: categPolo,
        Video:
          "https://www.youtube.com/embed/zpOULjyy-n8?rel=0&start=20&controls=1",
      });
    }
    if (id === "Camiseta" && Genero === "Mujer") {
      var categCamisetaM = id + "/" + Genero;

      //obtenerSeccion("/"+categCamisetaM)
      GetRuta({
        Categoria: categCamisetaM,
        Video:
          "https://www.youtube.com/embed/zpOULjyy-n8?rel=0&start=20&controls=1",
      });
    }
    if (id === "Camiseta" && Genero === "Hombre") {
      var categCamisetaH = id + "/" + Genero;

      //obtenerSeccion("/"+categCamisetaH)
      GetRuta({
        Categoria: categCamisetaH,
        Video:
          "https://www.youtube.com/embed/zpOULjyy-n8?rel=0&start=20&controls=1",
      });
    }
    if (id === "Hoodie") {
      var categHoodie = id + "/Hombre";
      //obtenerSeccion("/"+id)
      GetRuta({
        Categoria: categHoodie,
        Video:
          "https://www.youtube.com/embed/zpOULjyy-n8?rel=0&start=20&controls=1",
      });
    }
    if (id === "pantaloneta") {
      var categHoodie = id + "/Hombre";
      //obtenerSeccion("/"+id)
      GetRuta({
        Categoria: categHoodie,
        Video:
          "https://www.youtube.com/embed/zpOULjyy-n8?rel=0&start=20&controls=1",
      });
    }
    if (id === "jogger") {
      var categHoodie = id + "/Hombre";
      //obtenerSeccion("/"+id)
      GetRuta({
        Categoria: categHoodie,
        Video:
          "https://www.youtube.com/embed/zpOULjyy-n8?rel=0&start=20&controls=1",
      });
    }
  };
  useEffect(() => {
    //$(".carousel").attr("data-interval", "2000");
    /*$('.carousel').carousel({
            pause: "true"
        });*/

    /*$('.carousel').carousel({
            ride: true
          })*/

    Condicion();
    /*$('.carousel').carousel({
            ride: "true"
        });*/
    SetLandingHeader({ Estado: true });
    /*$('.carousel').on('touchstart', function(event){
            const xClick = event.originalEvent.touches[0].pageX;
            $(this).one('touchmove', function(event){
                const xMove = event.originalEvent.touches[0].pageX;
                const sensitivityInPx = 5;
        
                if( Math.floor(xClick - xMove) > sensitivityInPx ){
                    $(this).carousel('next');
                }
                else if( Math.floor(xClick - xMove) < -sensitivityInPx ){
                    $(this).carousel('prev');
                }
            });
            $(this).on('touchend', function(){
                $(this).off('touchmove');
            });
          });*/

    setTimeout(function () {
      activar();
    }, 3000);

    //obtenerSeccion(RutaL.Categoria)//"/Camiseta/Hombre" Product.length > 0
  }, []);

  return (
    <div>
      {Product.length > 0 ? (
        <div className="VideoTop">
          <div className="container">
            <div className="row toplanding">
              {/*<div className="embed-responsive embed-responsive-16by9" style={{marginTop: "150px"}}>
                    <iframe title="Video" className="embed-responsive-item" src={Ruta.Video} allowFullScreen></iframe>
                </div>  style={{display: "flex",  flexDirection: "row", justifyContent: "space-between",alignItems: "center"}}*/}

              <div
                className="col-12 col-sm-12 col-md-6 col-sm-lg-6"
                style={{ textAlign: "-webkit-center" }}
              >
                <div
                  id="carouselExampleControls"
                  className="carousel slide carousel-fade"
                  data-interval="2000"
                  style={{ maxWidth: "400px" }}
                >
                  <div className="carousel-inner">
                    {Product.map((imagen, index) =>
                      index > 0 ? (
                        <div className="carousel-item " key={imagen.Referencia}>
                          <img
                            style={{ maxWidth: "400px" }}
                            className="d-block w-100"
                            src={
                              "https://hosting.basic.dekada.co/BasicCouture/img/Productos/Ref " +
                              imagen.Referencia +
                              ".webp"
                            }
                            alt="Third slide"
                          />
                        </div>
                      ) : (
                        <div
                          className="carousel-item active"
                          key={imagen.Referencia}
                        >
                          <img
                            style={{ maxWidth: "400px" }}
                            className="d-block w-100"
                            src={
                              "https://hosting.basic.dekada.co/BasicCouture/img/Productos/Ref " +
                              imagen.Referencia +
                              ".webp"
                            }
                            alt="First slide"
                          />
                        </div>
                      )
                    )}
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-sm-lg-6">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <hr
                    style={{
                      color: "#500f0f",
                      backgroundColor: "#500f0f",
                      height: 1,
                      borderColor: "#500f0f",
                      marginBottom: "0px",
                      marginTop: "10px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <p className="Precios">Precio Mayorista</p>
                      <p className="ValuesPrecio">
                        {Product[0].PrecioMayorista}
                      </p>
                    </div>
                    <div
                      style={{
                        borderLeft: "2px solid #500f0f",
                        marginLeft: "20px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        <p className="Precios">Precio Minorista</p>
                        <p className="ValuesPrecio">
                          {Product[0].PrecioMinorista}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{
                      color: "#500f0f",
                      backgroundColor: "#500f0f",
                      height: 1,
                      borderColor: "#500f0f",
                      marginBottom: "0px",
                      marginTop: "20px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div className="compra">
                      <img
                        className="images"
                        src="/img/Landing/compra-protegida.png"
                      />
                      <div
                        style={{
                          borderLeft: "3px solid #500f0f",
                          marginLeft: "20px",
                          textAlign: "center",
                        }}
                      >
                        <p className="ValuesPrecio">Compra protegida</p>
                      </div>
                    </div>

                    <div className="pagoM">
                      <img className="images" src="/img/Landing/pago@72x.png" />
                      <div
                        style={{
                          borderLeft: "3px solid #500f0f",
                          marginLeft: "20px",
                          textAlign: "center",
                        }}
                      >
                        <p className="ValuesPrecio">
                          Disponible pago contra entrega
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{
                      color: "#500f0f",
                      backgroundColor: "#500f0f",
                      height: 1,
                      borderColor: "#500f0f",
                      marginBottom: "0px",
                      marginTop: "10px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  />
                  <Link
                    to={"/Mercado/" + Ruta.Categoria}
                    style={{ marginTop: "20px" }}
                    onClick={() => CambioProductos("Minoristas")}
                    target="_blank"
                  >
                    <p
                      className="botonLimpiarFiltros text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                    >
                      Quiero comprar una unidad.
                    </p>
                  </Link>
                  <Link
                    to={"/Mayoristas/" + Ruta.Categoria}
                    onClick={() => CambioProductos("Mayoristas")}
                    target="_blank"
                  >
                    <p
                      className="botonLimpiarFiltros text-center FuenteBebas"
                      style={{ cursor: "pointer", paddingTop: "30px" }}
                    >
                      Quiero comprar al por mayor.
                    </p>
                  </Link>
                  <a href="https://wa.link/nhyxt9" target="_blank">
                    <p
                      className="botonLimpiarFiltros text-center FuenteBebas"
                      style={{ cursor: "pointer", paddingTop: "30px" }}
                    >
                      Quiero contactar un asesor.
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <hr
              style={{
                color: "#500f0f",
                backgroundColor: "#500f0f",
                height: 1,
                borderColor: "#500f0f",
                marginBottom: "100px",
                marginTop: "100px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            />

            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <p className="titles">Clientes satisfechos</p>
                <p className="parrafo">
                  {" "}
                  En Basic Couture la prioridad es que los clientes se encuentren
                  satisfechos con nuestros productos, siempre atendiendo sus
                  necesidades con amabilidad y profesionalismo
                </p>
              </div>

              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6"
                style={{
                  background: 'url("/img/Landing/iphone-mockup.png")',
                  width: "700px",
                  height: "500px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  textAlign: "-webkit-center",
                  backgroundSize: "contain",
                }}
              >
                <div
                  id="carouselClientes"
                  className="carousel slide "
                  data-ride="carousel"
                  data-interval="2000"
                  style={{ width: "236px" }}
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block cel"
                        src="/img/Landing/Cliente16.jpeg"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block cel"
                        src="/img/Landing/Cliente15.jpeg"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block cel"
                        src="/img/Landing/Cliente14.jpeg"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block cel"
                        src="/img/Landing/Cliente13.jpeg"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block cel"
                        src="/img/Landing/Cliente12.jpeg"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block cel"
                        src="/img/Landing/Cliente11.jpeg"
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselClientes"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselClientes"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <hr
              style={{
                color: "#500f0f",
                backgroundColor: "#500f0f",
                height: 1,
                borderColor: "#500f0f",
                marginBottom: "100px",
                marginTop: "100px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            />
            <div className="row">
              <div className="col-12 " style={{ textAlign: "center" }}>
                <p className="titles">Nuestas alianzas</p>
                <p className="parrafo">
                  {" "}
                  Ahora puedes encontrar nuestras tiendas oficiales en los
                  Marketplace que más te gustan, para que compres tus prendas
                  favoritas con total confianza
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-4 col-sm-4 col-md-4 col-lg-4 "
                style={{ textAlign: "center" }}
              >
                <img
                  className="Logos"
                  src="/img/Landing/LOGO-MERCADOLIBRE.png"
                ></img>
              </div>
              <div
                className="col-4 col-sm-4 col-md-4 col-lg-4 "
                style={{ textAlign: "center" }}
              >
                <img className="Logos" src="/img/Landing/LOGO-LINIO.png"></img>
              </div>
              <div
                className="col-4 col-sm-4 col-md-4 col-lg-4"
                style={{ textAlign: "center" }}
              >
                <img className="Logos" src="/img/Landing/LOGO-DAFITI.png"></img>
              </div>
            </div>
          </div>
          <div className="container">
            <hr
              style={{
                color: "#500f0f",
                backgroundColor: "#500f0f",
                height: 2,
                borderColor: "#500f0f",
                marginBottom: "100px",
                marginTop: "100px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            />
            <div className="row">
              <div className="col-12 " style={{ textAlign: "center" }}>
                <p className="titles">Medios de pago</p>
                <p className="parrafo">
                  {" "}
                  Tenemos muchas opciones para que puedas comprar en nuestra
                  página de forma segura
                </p>
              </div>
            </div>

            <div className="row">
              <div
                className="col-4 col-sm-4 col-md-4 col-lg-4 "
                style={{ textAlign: "center" }}
              >
                <img
                  className="Logos"
                  src="/img/Landing/LOGO-BANCOLOMBIA.png"
                ></img>
              </div>
              <div
                className="col-4 col-sm-4 col-md-4 col-lg-4"
                style={{ textAlign: "center" }}
              >
                <img
                  className="Logos"
                  src="/img/Landing/LANDING-NEQUI.png"
                ></img>
              </div>
              <div
                className="col-4 col-sm-4 col-md-4 col-lg-4"
                style={{ textAlign: "center" }}
              >
                <img className="pse" src="/img/Landing/LOGO-PSE.png"></img>
              </div>
            </div>
            <div className="row">
              <div
                className="col-6 col-sm-6 col-md-4 col-lg-4 "
                style={{ textAlign: "center" }}
              >
                <img
                  className="Logos"
                  src="/img/Landing/LOGO-SUPERGIROS.png"
                ></img>
              </div>
              <div
                className="col-6 col-sm-6 col-md-4 col-lg-4 "
                style={{ textAlign: "center" }}
              >
                <img className="Logos" src="/img/Landing/LOGO-EFECTY.png"></img>
              </div>
            </div>
          </div>
          <div className="container">
            <hr
              style={{
                color: "#500f0f",
                backgroundColor: "#500f0f",
                height: 2,
                borderColor: "#500f0f",
                marginBottom: "0px",
                marginTop: "100px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            />
            <div className="row" style={{ marginTop: "30px" }}>
              <div className="col-12 " style={{ textAlign: "center" }}>
                <p className="titles" style={{ marginTop: "100px" }}>
                  Aliados logisticos
                </p>
                <p className="parrafo" style={{ textAlign: "center" }}>
                  {" "}
                  ¡Queremos que tu experiencia de compra sea la mejor! <br />{" "}
                  Por esto contamos con los mejores aliados para que tus prendas
                  lleguen a su destino de forma rápida y segura
                </p>
              </div>
            </div>

            <div className="row">
              <div
                className="col-6 col-sm-6 col-md-6 col-lg-6 "
                style={{ textAlign: "center" }}
              >
                <img className="Logos" src="/img/Landing/LOGO-ENVIA.png"></img>
              </div>
              <div
                className="col-6 col-sm-6 col-md-6 col-lg-6"
                style={{ textAlign: "center" }}
              >
                <img
                  className="Logos"
                  src="/img/Landing/LOGO-SERVIENTREGA.png"
                ></img>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "100px" }}>
              <div
                className="col-6 col-sm-6 col-md-6 col-lg-6 "
                style={{ textAlign: "center" }}
              >
                <img
                  className="Logos"
                  src="/img/Landing/LOGO-INTERRAPIDISIMO.png"
                ></img>
              </div>
              <div
                className="col-6 col-sm-6 col-md-6 col-lg-6 "
                style={{ textAlign: "center" }}
              >
                <img
                  className="Logos"
                  src="/img/Landing/LOGO-DEPRISA.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Landing;
