import { useState, useContext, createContext } from 'react'


/** Se crea un contexto para usar en NewIndex y Mercado
 * para manejar las categorias
 */
const CategoriasContext = createContext();

export const useCategContext = () => {
  return useContext(CategoriasContext);
}

export const CategoriasProvider = ({children}) => {

  const [categorias, setCategorias] = useState([
    /** Categoria Ejemplo */
      //  [
      //     "NOMBRE CATEGORIA",
      //     "URL_IMAGEN_CATEGORIA",
      //     ["LISTA_CATEGORIAS"],
      //     ["LISTA_SUBCATEGORIAS"],
      //     ["LISTA_MARCAS"],
      //     ["GENERO"],
      //     "CONSULTA_PRODUCTOS_NUEVOS",
      //     "Hombre ó Mujer",
      //     "IDENTIFICACION_CATEGORIA",
      //     "CONSULTA_PRODUCTOS_DESCUENTO"
      //  ],
      // [
      //   "NEW",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/carousel/fondo2Movil.png",  
      //   [],
      //   [],
      //   [],
      //   [],
      //   true,
      //   "New",
      //   "Nuevo",
      //   false
      // ],
      // [
      //   "CAMISETAS ESTAMPADAS HOMBRE",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20ESTAMPADAS%20HOMBRE.webp",
      //   [{ Categoria: "607ed63e521b1c0910a581aa" }],
      //   [ { Subcategoria: "607ed65a521b1c0910a581bf" },
      //   ],
      //   [],
      //   [{ Genero: "607ed653521b1c0910a581b8" }],
      //   false,
      //   "Hombre",
      //   "Estampada",
      //   false,
      // ],
      //  
  
      // [
      //   "CAMISETAS BÁSICAS HOMBRE",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20BASICAS.webp",
      //   [{ Categoria: "607ed63e521b1c0910a581aa" }],
      //   [
      //     { Subcategoria: "607ed65a521b1c0910a581bd" },
      //     { Subcategoria: "65a53552e8989878c228d032" },
      //   ],
      //   [],
      //   [{ Genero: "607ed653521b1c0910a581b8" }],
      //   false,
      //   "Hombre",
      //   "Basica",
      //   false,
      // ],
  
      [
        "BLUSAS BÁSICAS DK",
        "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20BLUSAS.webp",
        [
          { Categoria: "6583763e8dfd4f911a2d0388" }, // Seamless - Tubular
        ],
        [
          { Subcategoria: "607ed65a521b1c0910a581bd" }, // basica
          { Subcategoria: "65a53552e8989878c228d032" }, // general
        ],
        [],
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "Blusa",
        false,
      ],
      [
        "TOPS",
        "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20TOPS.webp",
        [
          { Categoria: "6584546244954018ca00865e" }, // Tops
        ],
        [
          { Subcategoria: "65a53552e8989878c228d032" }, // general
        ],
        [], // { Marca: "64f8df51ab8f6199b7154137" }
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "Top",
        false,
      ],
      // [
      //   "SETS",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20SETS.webp",
      //   [{ Categoria: "6584551544954018ca008661" }],
      //   [],
      //   [],
      //   [ { Genero: "607ed653521b1c0910a581b9" } ],
      //   false,
      //   "Mujer",
      //   "Sets",
      //   false,
      // ],
      [
        "BIKERS",
        "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20BIKERS.webp",
        [{ Categoria: "6525a7906b44bb8bf3323210" }],
        [],
        [],
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "Biker",
        false,
      ],
      [
        "VESTIDOS",
        "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20VESTIDOS.webp",
        [ { Categoria: "65578d4701bf5211b63d14bc" } ],
        [],
        [],
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "Vestido",
        false,
      ],
      // [
      //   "ENTERIZOS",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20ENTERIZOS.webp",
      //   [ { Categoria: "65f075f9f379953d314aeca3" } ],
      //   [],
      //   [],
      //   [ { Genero: "607ed653521b1c0910a581b9" } ],
      //   false,
      //   "Mujer",
      //   "Enterizo",
      //   false,
      // ],
      [
        "BODYS",
        "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20BODYS.webp",
        [
          { Categoria: "655bad5a1cdc932b9e974a01" }, // Body
        ],
        [
          { Subcategoria: "65a53552e8989878c228d032" }, // general
        ],
        [],
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "Body",
        false,
      ],
      [
        "ROPA INTERIOR",
        "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20ROPA%20INTERIOR.webp",
        [
          { Categoria: "65f06ae2f379953d314aeca2" }, // Ropa interior
        ],
        [
          { Subcategoria: "65a53552e8989878c228d032" }, // general
        ],
        [],
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "Interior",
        false,
      ],
  
      // [
      //   "CAMISETAS ESTAMPADAS DK",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BasicasM.png",
      //   { Categoria: "607ed63e521b1c0910a581aa" },
      //   { Subcategoria: "607ed65a521b1c0910a581bf" },
      //   [{ Marca: "64f8df51ab8f6199b7154137" }],
      //   { Genero: "607ed653521b1c0910a581b9" },
      //   false,
      //   "Mujer",
      //   "CamisetaEsDk",
      //   false,
      // ],
  
      // [
      //   "VALTIMOR",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Camisetas%20hombre.png",
      //   { Categoria: "607ed63e521b1c0910a581aa" },
      //   null,
      //   [
      //     { Marca: "607ed64c521b1c0910a581b6" }, //fishbone
      //     { Marca: "607ed64c521b1c0910a581b5" }, //staff
      //     { Marca: "607ed64c521b1c0910a581b4" }, //urbi
      //     { Marca: "607ed64c521b1c0910a581b7" }, //valtimor
      //   ],
      //   { Genero: "607ed653521b1c0910a581b8" },
      //   false,
      // ],
  
      // [
      //   "POLOS",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20POLOS.webp",
      //   [{ Categoria: "607ed63f521b1c0910a581b1" }],
      //   [], // subcategoria
      //   [], // Marca
      //   [ { Genero: "607ed653521b1c0910a581b8" } ],
      //   false,
      //   "Hombre",
      //   "Polo",
      //   false,
      // ],
      // [
      //   "GORRAS",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/CATEGORIA%20GORRAS.webp",
      //   [{ Categoria: "607ed63e521b1c0910a581ac" }],
      //   [], // subcategoria
      //   [], // marca
      //   [ { Genero: "658316d88dfd4f911a2d0379" } ], // genero
      //   false,
      //   "Unisex",
      //   "Gorra",
      //   false,
      // ],
  
      // [
      //   "PANTALONETAS",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Pantalonetas.png",
      //   { Categoria: "607ed63e521b1c0910a581b0" },
      //   null,
      //   null,
      //   null,
      //   false,
      //   "Hombre",
      //   "Pantalonetas",
      // ],
      // [
      //   "HOODIES HOMBRE",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Hoodies%20Hombre.png",
      //   { Categoria: "607ed63e521b1c0910a581ad" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b8" },
      //   false,
      //   "Hombre",
      //   "Hoodie",
      // ],
      // [
      //   "HOODIES MUJER",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Hoodies%20Mujer.png",
      //   { Categoria: "607ed63e521b1c0910a581ad" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b9" },
      //   false,
      //   "Mujer",
      //   "Hoodie",
      // ],
      // [
      //   "JOGGER HOMBRE",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Jogger%20hombre.png",
      //   { Categoria: "607ed63e521b1c0910a581af" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b8" },
      //   false,
      //   "Hombre",
      //  "Jogger",
      // ],
      // [
      //   "JOGGER MUJER",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Jogger%20mujer.png",
      //   { Categoria: "607ed63e521b1c0910a581af" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b9" },
      //   false,
      //   false,
      //   "Mujer",
      //   "Jogger",
      // ],
      // [
      //   "NIÑOS",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/carousel/fondo3Movil.png",
      //   null,
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581ba" },
      //   false,
      //  "Niño",
      //  "Camiseta",
      // ],
      // [
      //   "REBAJAS",
      //   "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Descuentos.webp",
      //   [],
      //   [],
      //   [],
      //   [],
      //   false,
      //   "Todo",
      //   "Descuento",
      //   true,
      // ],
  ]);

  return (
    <CategoriasContext.Provider value={categorias}>
      {children}
    </CategoriasContext.Provider>
  )

}