//Zona de importacion de componentes externos.
import React, { useEffect, useState, useRef } from "react";

//Zona de importacion de componentes internos.
import GrupoTallas from "./GrupoTallas";
import { Link } from "react-router-dom";
import "./static/css/Producto/producto.css";
import "./static/css/Producto/productov2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductoV2 = ({
  ViewDetalle,
  productoCompra,
  setProductoCompra,
  activado,
  setActivado,
  cliente,
  index,
  producto,
  carrito,
  modificarCarritoState,
  headerState,
  modificarHeaderState,
  errorState,
  modificarErrorState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  spinnerState,
  modificarSpinnerState,
  actualizarSessionStorage,
  tallasState,
  modificarTallasState,
  refNoCatalogoState,
  modificarRefNoCatalogoState,
  vendedor,
  modificarVendedorState,
  Bodega,
  setBodega,
  checkedProductos,
  setCheckedProductos,
}) => {
  const [tallas, setTallas] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [individualChecked, setIndividualChecked] = useState(true);
  const [toggleVariantes, setToggleVariantes] = useState(false);
  const [imgUrl, setImgUrl] = useState(`${recursosUrlState}/img/Productos/Ref ${producto[0].Referencia}.webp`)
  const [productoDetalle, setProductoDetalle] = useState(producto[0].Referencia);
  const ruta = useRef(window.location.pathname);


  const handleProductSelect = (productId) => {
    localStorage.setItem("selectedProductId", productId);
  };

  // Maneja el evento onMouseOver para cambiar la imagen
  const handleMouseOver = (variante) => {
    setImgUrl(`${recursosUrlState}/img/Productos/Ref ${variante}.webp`);
    setProductoDetalle(variante);
  };

  // Maneja el evento onMouseOut para restaurar la imagen original
  const handleMouseOut = (variante) => {
    setImgUrl(`${recursosUrlState}/img/Productos/Ref ${variante}.webp`);
    setProductoDetalle(producto[0].Referencia);
  };

  const compraRapida = (prod) => {
    setProductoCompra(prod);
  }

  useEffect(() => {

    const storedProductId = localStorage.getItem("selectedProductId");
    if (storedProductId) {
      setSelectedProductId(storedProductId);
    }
  }, []);

  useEffect(() => {
    const rutaCompleta = window.location.pathname;
    const partesRuta = rutaCompleta.split('/');
    const Mercado = partesRuta[1];
    const element = document.getElementById(selectedProductId);
    if (element  && Mercado === "Mercado") {
      element.scrollIntoView({ behavior: "instant" }); // para animar la visualizacion { behavior: 'smooth' })
      setTimeout(() => {
      }, 30000);
    }
  }, [selectedProductId]);

  //Añade el punto de miles al precio ingresado y lo retorna.
  const formatNumber = (amount) => {
    var temp = Math.round(amount) + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  //Retorna el precio ingresado sin signos de peso ni puntos.
  const arreglarPrecio = (precio) => {
    var separacion = precio;
    if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
    var separacion2 = separacion.split(".");
    return parseFloat(separacion2[0] + separacion2[1]);
  };

  const calcularDescuento = (precio, descuento) => {
    const precioInt = arreglarPrecio(precio);
    const descuentoAplicar = parseFloat(descuento);
    return formatNumber(precioInt - precioInt * (descuentoAplicar / 100));
  };

  // useEffect(() => {
  //   setIndividualChecked(checkedProductos)
  // }, [checkedProductos])
  
  return (
    <div
      onMouseOver={()=> setToggleVariantes(true)}
      onMouseLeave={()=> setToggleVariantes(false)}
    >
      <div>
        {headerState["Cliente"] !== "Minoristas" ? (
          <img 
          className="img-producto transicion"
          src={imgUrl} 
          alt={`Ref ${producto[0].Referencia}`}
          onError={(e) => e.target.src = `${recursosUrlState}/img/General/base2.png`}
        />
        ) : (
        <Link to={"/Detalle/" + productoDetalle}>
          <img 
            className="img-producto transicion"
            src={imgUrl} 
            alt={`Ref ${producto[0].Referencia}`}
            onError={(e) => e.target.src = `${recursosUrlState}/img/General/base2.png`}
          />
        </Link>
        )}
        <div style={{textAlign:"center"}}>
          <button 
            className="btn-compra"
            onClick={()=> compraRapida(producto)}
          >Compra rápida</button>
        </div>
        {toggleVariantes? (
          <div className="variantes">
            {producto.map((variante) => (
              <img
                key={variante.Referencia}
                alt={producto[0].Referencia}
                src={recursosUrlState + "/img/Productos/Ref " +
                  variante.Referencia + ".webp"}
                onMouseOver={()=> handleMouseOver(variante.Referencia)}
                // onMouseOut={() => handleMouseOut(producto[0].Referencia)}
                onError={(e) => e.target.src = `${recursosUrlState}/img/General/base2.png`}
              />
            ))}
          </div>
        ) : (
          <div className="variantes">
            <div>
              <div>
                <p>Ref {producto[0].RefBase}</p>
                {ruta.current==="/Mayoristas"? (
                  <>
                    {producto[0].DescuentoMayorista !== 0? (
                      <div className="precios">
                        <p>$ {calcularDescuento(
                          producto[0].PrecioMayorista,
                          producto[0].DescuentoMayorista
                        )}
                        </p>
                        <p className="descuento">$ {producto[0].PrecioMayorista}</p>
                      </div>
                    ) : (
                      <p>$ {producto[0].PrecioMayorista} </p>
                    )}
                  </>
                ) : (
                  <>
                    {producto[0].DescuentoMinorista !== 0? (
                      <div className="precios">
                        <p>$ {calcularDescuento(
                          producto[0].PrecioMinorista,
                          producto[0].DescuentoMinorista
                        )}
                        </p>
                        <p className="descuento">$ {producto[0].PrecioMinorista}</p>
                      </div>
                    ) : (
                      <p>$ {producto[0].PrecioMinorista} </p>
                    )}
                  </>
                )}
              </div>
              
            </div>

          </div>
        )}
        
      </div>
    </div>
  );
};

export default ProductoV2;
